/* .........................Logout drop-down........................................... */
#logout-container {
    position: relative;
  }
  
  .logout-dropdown {
    /* position: absolute;
    top: 100%;
    left: 0;
   
   
    border: 1px solid black;
    box-shadow: 0px 8px 16px 0px black; */
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 100%;
    left: -40%;
    transform: translate(-50%, 15%);
    background-color: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 162px;
    z-index: 1;
  }
  .logout-dropdown::before{
    content: "";
    position: absolute;
    top: -25%;
    left: 80%;
    transform: translate(-90%, -25%);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
  #edit-button {
    width: 160px;
    height: 39px;
    border: none;
    background-color: white;
    font-family: 'Poppins', sans-serif;
}
#edit-button:hover{
    background-color: orange;
    color: black;
}

 #admin-logout{
    width: 160px;
    height: 39px;
    border: none;
    font-family: 'Poppins', sans-serif;
    background-color: white;
 }
 #admin-logout:hover{
    background-color: orange;
    color: black;
 }

/* .........................Logout drop-down........................................... */

.platform2{
    overflow: hidden;
}

/* Toggle Styles */

#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled {
    padding-left: 250px;
}

#sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 250px;
    width: 0;
    height: 100%;
    margin-left: -250px;
    overflow-y: auto;
    background: #000;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
    width: 250px;
}

#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
    background-color: orange;
}

#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -250px;
}

/* Sidebar Styles */

.sidebar-nav {
    position: absolute;
    top: 0;
    width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-nav li {
    text-indent: 20px;
    line-height: 40px;
}

.sidebar-nav li span {
    display: block;
    text-decoration: none;
    color: #999999;
}

.sidebar-nav li span:hover {
    text-decoration: none;
    color: #fff;
    background: rgba(255,255,255,0.2);
}

.sidebar-nav li span:active,
.sidebar-nav li span:focus {
    text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
}

.sidebar-nav > .sidebar-brand span {
    color: #999999;
}

.sidebar-nav > .sidebar-brand span:hover {
    color: #fff;
    background: none;
}
/* .......................................................... */
#icon1 {
    font-size: 30px;
    margin-bottom: 6px;
    margin-left: 60px;
}
.part-sec1{
    font-size: 30px;
}
/* .admin-set span{
    display: flex;
    flex-direction: row;
    gap: 20px;
} */

/* ............................................................. */

#menu-toggle{
    font-size: 30px;
}
#image-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
}
#image {
    height: 62px;
    width: 98px;
    /* margin-left: 21px; */
}
.disp{
    display: flex;
    justify-content: space-between;
    
}
#log-out{
    font-size: 12px;
    height: 36px;
    width: 62px;
    margin-top: 14px;
    border-radius: 12px;
    border: none;

}
#log-out:hover{
    background-color: black;
    color: white;
}
.board-sec {
    margin-top: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.board-sec a{
    text-decoration: none;
}
/* .part-sec1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
} */





.next{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 193px;
    
}

.segment{
    width: 81%;
    display: flex;
    justify-content: space-between;
}
.valid-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.change{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}
.valid-btn1{
    height: 50px;
    width: 124px;
    border: none;
    border-radius: 18px;
    background-color: #000;
    color: #fff;
    font-family: 'Poppins', sans-serif;
}
.valid-btn1:hover{
    background-color: orange;
    color: black;
}
#edit {
    background-color: orange;
    border: none;
    border-radius: 3px;
    width: 36px;
}
#edit:hover{
    background-color: black;
    color: white;

}
#dlt{
    background-color: orange;
    border: none;
    border-radius: 3px;
    width: 36px;
    
}
#dlt:hover{
    background-color: black;
    color: white;
}
.change {
    margin-top: 10px;
}
.top-heads h1{
    display: flex;
    justify-content: center;
    text-decoration: underline;
    height: 80px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.dash h1{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 50px;
}
.dash h4{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
#one{
    font-family: 'Poppins', sans-serif;  
}
#Hpage{
    font-family: 'Poppins', sans-serif;  
    font-weight: 400; 
}
#Num{
    font-family: 'Poppins', sans-serif;  
    font-weight: 600; 
    font-size: 20px;
}
#pge{
    font-family: 'Poppins', sans-serif;  
    font-weight: 600; 
    font-size: 20px; 
}
#action{
    font-family: 'Poppins', sans-serif;  
    font-weight: 600; 
    font-size: 20px;
}
#pg-sec{
    font-size: 23px;
    /* ................................................ */
    
        margin-top: 48px;
    
    /* ................................................... */
}
#menu-sec{
font-size: 23px;
margin-top: 20px;
}
/* .............................Table............................................. */

#preview-btn{
    border: none;
    background-color: orange;
    border-radius: 4px;
    font-size: 16px;
}
#preview-btn:hover{
    background-color: black;
    color: white;
}
#delete-btn{
    border: none;
    background-color: orange;
    border-radius: 4px; 
    font-size: 16px;
}
#delete-btn:hover{
    background-color: black;
    color: white;
}
#edited-btn{
    border: none;
    background-color: orange;
    border-radius: 4px; 
    font-size: 16px;
}
#edited-btn:hover{
    background-color: black;
    color: white;
}
.all_btns{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
/* ..................................................................................... */


@media(min-width:768px) {
    #wrapper {
        padding-left: 250px;
    }

    #wrapper.toggled {
        padding-left: 0;
    }

    #sidebar-wrapper {
        width: 250px;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 0;
    }

    #page-content-wrapper {
        padding: 20px;
        position: relative;
    }

    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
    }
}



@media only screen and (max-width: 600px) {
   
.next{
    height: 320px;
    
}
.segment{
    width: 86%;
}
}
@media only screen and (max-width: 569px){
    .segment {
        width: 94%;
    }
}



@media only screen and (min-width: 600px) {
    .next {
        
        height: 324px;
    }
    .segment {
        width: 86%;
    
    }
}
@media only screen and (min-width: 768px){
    .next {
        height: 229px;
    }
    .segment {
        width: 96%;
    }
}
@media only screen and (min-width: 1200px){
    .part-sec1{
        font-size: 24px;
    }
    #icon1{
        font-size: 24px;
        margin-bottom: 6px;
        margin-left: 75px;
    }
    #pg-sec{
        font-size: 19px;
    }
    #menu-sec{
        font-size: 19px;
    }
    .table-use{
        width: 80%;
    }
    .segment {
        width: 76%;
    }
    .next {
        height: 346px;
    }
}
@media only screen and (min-width: 1569px){
    .segment {
        width: 76%;
    }
}
@media only screen and (min-width: 1575px){
    .segment {
        width: 76%;
    }
}
@media only screen and (min-width: 1598px){
    .segment {
        width: 76%;
    }
}
@media only screen and (min-width: 1649px){
    .segment {
        width: 92%;
    }
}
@media only screen and (min-width: 1699px){
    .segment {
        width: 76%;
    }
}
@media only screen and (min-width: 1749px){
    .segment {
        width: 76%;
    }
}
@media only screen and (min-width: 1799px){
    .segment {
        width: 76%;
    }
}
@media only screen and (min-width: 1849px){
    .segment {
        width: 76%;
    }
}
@media only screen and (min-width: 1899px){
    .segment {
        width: 76%;
    }
}
@media only screen and (min-width: 1949px){
    .segment {
        width: 76%;
    }
}
@media only screen and (min-width: 1949px){
    .segment {
        width: 76%;
    }
}
/* @media(max-width:600px){
    .next {
        height: 317px;
    }
}
@media(max-width:380px){
    .dash h4 {
        font-size: 18px;
    }
}

@media(min-width:600px){
    .next {
        height: 20rem;
    }
} */