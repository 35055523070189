.main_page{
    overflow: hidden;
}
.over-heads{
    background: url('../assets/Images/orange.png');
}

.file-input{
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}
.file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  
  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 10px 12px;
    background-color: #4245a8;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  }
  
  .file-input__label svg {
    height: 16px;
    margin-right: 4px;
  }


  /* ......................................................................... */

  .form-head{
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(190, 198, 185); */
}
.min-forms{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
}
.min-forms button {
    background-color: black;
    color: white;
    border: none;
    height: 42px;
    width: 94px;
    border-radius: 7px;
    font-family: 'Poppins', sans-serif;
}
.min-forms button:hover{
    background-color: white;
    color: black;
}
.min-forms label{
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
}
.min-forms input{
    height: 50px;
    border: none;
    border-radius: 20px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}
/* .................................................................................................................... */
.text-header{
    display: flex;
    justify-content: center;
    align-items: center;
}
.contents-text{
    display: flex;
    justify-content: center;
    align-items: center;
}

.texts{
    height: 617px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}