

#footer-about address a{
    text-decoration: none;
    color: #a8a8a8;
}

/* ......................................... */
footer#site-footer {
    margin: 0 auto;
    width: 100%;
    background: #222222;
    padding: 1rem;
    color: #a8a8a8;
}

/* footer#site-footer a {
    color: #a8a8a8;
}

footer#site-footer a:hover {
    color: #00c3ce;
} */

footer#site-footer h1 {
    color: #cecece;
}

footer#site-footer section.horizontal-footer-section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 2.38rem 12rem;
}

footer#site-footer section.horizontal-footer-section#footer-top-section {
    justify-content: center;
}

footer#site-footer #footer-logo {
    margin-right: auto;
    flex-shrink: 0;
}

footer#site-footer #footer-logo img {
    height: 2.28rem;
}

footer#site-footer ul#footer-top-menu {
    padding: 0;
    margin: 0;
}

footer#site-footer ul#footer-top-menu li {
    display: inline;
    list-style-type: none;
    margin-right: 1.32rem;
    font-weight: bold;
}

footer#site-footer ul#footer-top-menu li a {
    text-decoration: none;
}

footer#site-footer .footer-top-menu-item-active {
    position: relative;
}

footer#site-footer .footer-top-menu-item-active a {
    color: #007ba6;
}

footer#site-footer .footer-top-menu-item-active a:hover {
    color: #00c3ce;
}

footer#site-footer .footer-top-menu-item-active::before {
    content: "";
    background: #007ba6;
    padding: 0.15rem 0;
    width: 100%;
    position: absolute;
    top: 1.68em;
}

footer#site-footer .footer-top-menu-item-active:hover::before {
    background: #00c3ce;
    transition-duration: 0.6s;
}

footer#site-footer #footer-buttons-container {
    margin-left: auto;
    flex-shrink: 0;
}

footer#site-footer a.footer-button {
    background: transparent;
    border: 0.15rem solid #a8a8a8;
    border-radius: 1.38rem;
    padding: 0.5rem 1rem;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
}

footer#site-footer a.footer-button:hover {
    border-color: #00c3ce;
}

footer#site-footer section.horizontal-footer-section#footer-middle-section {
    align-items: flex-start;
}

footer#site-footer .footer-columns {
    flex-basis: 18%;
    padding-right: 1rem;
}

footer#site-footer .footer-columns-large {
    flex-basis: 46%;
    padding-right: 2rem;
}

/* footer#site-footer .footer-columns h1 {
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 800;
} */

footer#site-footer .footer-columns.footer-columns-large h1 {
    font-size: 18px;
    margin-top: 3rem;
    margin-bottom: 1.32rem;
    font-weight: 600;
}

footer#site-footer .footer-columns.footer-columns-large h1:first-child {
    margin-top: 0;
}

/* footer#site-footer ul.footer-column-menu {
    margin: 0;
    padding: 0;
}

footer#site-footer ul.footer-column-menu li {
    list-style-type: none;
    margin-bottom: 0.68rem;
    font-size: 0.88rem;
} */

footer#site-footer #footer-about address {
    font-style: normal;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

footer#site-footer #footer-about address p span {
    margin-right: 0.68rem;
    color: #FBA327;
}

.address-details{
    display: flex;
    cursor: pointer;
}

footer#site-footer #footer-about a.footer-button {
    margin-top: 0.32rem;
}

footer#site-footer section.horizontal-footer-section#footer-bottom-section {
    padding: 1.32rem 0;
    margin: 2rem 11rem 1.32rem 11rem;
    border-top: 0.05rem solid #666666;
}

footer#site-footer #footer-social-buttons {
    justify-self: flex-end;
    margin-left: auto;
}

#footer-social-buttons span{
    border-bottom: 2px solid #FBA327;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
}

/* footer#site-footer #footer-social-buttons img {
    margin-left: 0.68rem;
}

footer#site-footer #footer-social-buttons img:hover {
    cursor: pointer;
} */

#footer-copyright-info{
    font-family: 'Poppins', sans-serif;
}

#footer-copyright-info a{
    color: #FBA327;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
}
#img-logo{
    height: 80px;
}




@media(max-width:1110px){
    footer#site-footer section.horizontal-footer-section {
        padding: 2.38rem 8rem;
    }

    footer#site-footer section.horizontal-footer-section#footer-bottom-section {
        margin: 2rem 9rem 1.32rem 8rem;
    }
    footer#site-footer .footer-columns.footer-columns-large h1 {
        /* font-size: 16px;
        margin-top: 3rem;
        margin-bottom: 1.32rem;
        font-weight: 600; */
        white-space: nowrap;
    }
    #location{
        /* font-size: 14px; */
        white-space: nowrap;
    }
    
}


@media screen and (max-width: 959px) {
    #site-footer .footer-columns.footer-columns-large h1 {
        font-size: 20px;
        
    }
    address {
        margin-bottom: 1rem;
        font-style: normal;
        line-height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    footer#site-footer {
        width: 100%;
    }

    footer#site-footer section.horizontal-footer-section {
        flex-direction: column;
        flex-wrap: wrap;
    }

    footer#site-footer section.horizontal-footer-section div {
        flex-basis: 100%;
        width: 100%;
    }

    footer#site-footer ul li {
        display: block !important;
        padding: 1em !important;
        border-top: 1px solid #555555;
        border-bottom: none;
        border-left: none;
        border-right: none;
        font-size: 1.15rem !important;
    }

    footer#site-footer ul li:first-child {
        border-top: none;
    }

    footer#site-footer a.footer-button {
        display: block;
        text-align: center;
        max-width: 350px;
        margin: 0.32rem auto;
    }

    footer#site-footer section.horizontal-footer-section#footer-top-section div {
        margin-top: 0;
        margin-bottom: 1.68rem;
    }

    footer#site-footer #footer-logo {
        margin-right: 0;
    }

    footer#site-footer #footer-buttons-container {
        margin-left: 0;
    }

    footer#site-footer ul#footer-top-menu {
        width: 100%;
        margin: 2rem 0 0 0;
    }

    footer#site-footer .footer-top-menu-item-active::before {
        display: none;
    }

    footer#site-footer #footer-about {
        margin-bottom: 3rem;
    }

    footer#site-footer .footer-columns-large,
    footer#site-footer .footer-columns {
        padding-right: 0;
    }

    footer#site-footer .footer-columns {
        margin-top: 0;
        margin-bottom: 4rem;
    }

    footer#site-footer .footer-columns:last-child {
        margin-bottom: 0;
    }

    footer#site-footer .footer-columns h1 {
        text-align: center;
        font-size: 1.3rem;
        text-transform: uppercase;
        letter-spacing: 0.15rem;
    }

    footer#site-footer .footer-columns.footer-columns-large h1 {
        font-size: 20px;
        margin-top: 3rem;
        margin-bottom: 1.32rem;
        font-weight: 600;
    }

    footer#site-footer section#footer-bottom-section div {
        text-align: center;
    }

    footer#site-footer section.horizontal-footer-section#footer-bottom-section {
        margin: 1.32rem 0;
        padding: 0;
        border-top: 0;
    }

    footer#site-footer section#footer-bottom-section #footer-social-buttons {
        margin-top: 1.32rem;
    }
}

@media(max-width:768px){
    footer#site-footer section.horizontal-footer-section {
        padding: 2.38rem 8rem;
    }
}

@media(max-width:600px){
    footer#site-footer section.horizontal-footer-section{
        padding: 0;
    }
}
@media(max-width:360px){
    footer#site-footer .footer-columns.footer-columns-large h1 {
        font-size: 16px;
        
    }
}

@media only screen and (min-width: 1111px){
    footer#site-footer .footer-columns.footer-columns-large h1 {
        
        white-space: nowrap;
    }
    #location{
        
        white-space: nowrap;
    }
    footer#site-footer section.horizontal-footer-section {
        padding: 2.38rem 5rem;
        margin-left: 20px;
    }
}
@media only screen and (min-width: 1200px){
    footer#site-footer section.horizontal-footer-section {
        padding: 2.38rem 42px;
        margin-left: 16px;
    }
    footer#site-footer .footer-columns-large {
        flex-basis: 46%;
        padding-right: 2rem;
        margin-left: 61px;
    }
    #img-logo {
        height: 137px;
        margin-left: -39px;
    }
}
@media only screen and (min-width: 1280px){
    #img-logo {
        height: 137px;
        margin-left: -22px;
    }
}
@media only screen and (min-width: 1350px){
    #img-logo {
        height: 137px;
        margin-left: 0px;
    }
}
@media only screen and (min-width: 1400px){
    footer#site-footer section.horizontal-footer-section {
        padding: 2.38rem 65px;
        margin-left: 56px;
    }
    #img-logo {
        height: 137px;
        margin-left: -58px;
    }
}
@media only screen and (min-width: 1450px){
    footer#site-footer .footer-columns-large {
        flex-basis: 46%;
        padding-right: 2rem;
        margin-left: 88px;
    }
    #img-logo {
        height: 137px;
        margin-left: -28px;
    }
    footer#site-footer section.horizontal-footer-section {
        padding: 2.38rem 65px;
        margin-left: 23px;
    }
}
@media only screen and (min-width: 1550px){
    footer#site-footer section.horizontal-footer-section {
        padding: 2.38rem 39px;
        margin-left: 51px;
    }
    #img-logo {
        height: 137px;
        margin-left: -18px;
    }
}
@media only screen and (min-width: 1650px){
    footer#site-footer section.horizontal-footer-section {
        padding: 2.38rem 2px;
        margin-left: 141px;
    }
    #img-logo {
        height: 137px;
        margin-left: -54px;
    }
}
@media only screen and (min-width: 1800px){
    footer#site-footer section.horizontal-footer-section {
        padding: 2.38rem 2px;
        margin-left: 154px;
    }
}

/* @media only screen and (min-width: 1400px){
    footer#site-footer section.horizontal-footer-section {
        padding: 2.38rem 10rem;
        margin-left: 78px;
    }
}
@media only screen and (min-width: 1550px){
    footer#site-footer section.horizontal-footer-section {
        padding: 2.38rem 10rem;
        margin-left: 101px;
    }
}
@media only screen and (min-width: 1600px){
    footer#site-footer section.horizontal-footer-section {
        padding: 2.38rem 10rem;
        margin-left: 114px;
    }
}
@media only screen and (min-width: 1750px){
    footer#site-footer section.horizontal-footer-section {
        padding: 2.38rem 10rem;
        margin-left: 121px;
    }
}
@media only screen and (min-width: 1850px){
    footer#site-footer section.horizontal-footer-section {
        padding: 2.38rem 10rem;
        margin-left: 162px;
    }
} */