@import url('https://fonts.googleapis.com/css?family=Open+Sans');

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700&display=swap');

.success-email{
    overflow: hidden;
}

.verify-icon{
    color: greenyellow;
    font-size: 113px;
}
.verify h1{
    font-size: 32px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}
.verify p{
    font-family: 'Source Sans Pro', sans-serif;
}

.verify button {
    border: none;
    background-color: black;
    color: white;
    height: 60px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    width: 10rem;
    border-radius: 15px;
}

@media only screen and (min-width: 600px){
    .verify{
        margin-top: 13rem;
    }
}
@media only screen and (min-width: 1400px){
    .verify{
        margin-top: 14rem;
    }
}
