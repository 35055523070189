/* .swiper {
  width: 100%;
  height: 500px;
} */
#contents-slides {
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#align-head {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

#video1 {
  transition: 1s;
}

#video1:hover {
  scale: 1.07;
  transition: 1s;
}
#video2{
  transition: 1s;
}
#video2:hover {
  scale: 1.07;
  transition: 1s;
}
#video3{
  transition: 1s;
}
#video3:hover {
  scale: 1.07;
  transition: 1s;
}
#video4{
  transition: 1s;
}
#video4:hover {
  scale: 1.07;
  transition: 1s;
}
#video5{
  transition: 1s;
}
#video5:hover {
  scale: 1.07;
  transition: 1s;
}
#video6{
  transition: 1s;
}
#video6:hover {
  scale: 1.07;
  transition: 1s;
}
#video7{
  transition: 1s;
}
#video7:hover {
  scale: 1.07;
  transition: 1s;
}
#video8{
  transition: 1s;
}
#video8:hover {
  scale: 1.07;
  transition: 1s;
}
#video9{
  transition: 1s;
}
#video9:hover {
  scale: 1.07;
  transition: 1s;
}
#video10{
  transition: 1s;
}
#video10:hover {
  scale: 1.07;
  transition: 1s;
}

@media only screen and (max-width: 600px) {
  #video1 {
    width: 70%;
    height: 250px;
  }

  #video2 {
    /* width: 70%; */
    height: 250px;
  }

  #video3 {
    height: 260px;

  }

  #video4 {
    height: 248px;

  }

  #video5 {
    height: 248px;

  }

  #video6 {
    height: 248px;

  }

  #video7 {
    height: 248px;

  }

  #video8 {
    height: 248px;

  }

  #video9 {
    height: 248px;

  }

  #video10 {
    height: 267px;
    /* display: none;
    overflow: hidden; */
  }

  #setup {
    height: 541px;
  }

  .swiper-slide {
    width: 604.5px;
    margin-right: -55
  }

  /* .swiper-slide-active{
    width: 604.5px;
    margin-right: -55px;
  } */
  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, -6px);
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
    /* margin-top: 4px; */
  }

}



@media only screen and (min-width: 1200px) {
  .swiper {
    width: 82%;
    height: 100%;
  }


  /* .swiper-slide {
    width: 542px;
    margin-left: 45px;
} */
  .swiper-slide-next {
    width: 562px;
    margin-right: -100px;
  }

  /* #video1{
  width: 70%;
}
#video2{
  width: 70%;
} */
  /* #video3{
  width: 43%;
}
#video4{
  width: 43%;
}
#video5{
  width: 43%;
}
#video6{
  width: 43%;
}
#video7{
  width: 43%;
}
#video8{
  width: 43%;
}
#video9{
  width: 43%;
}
#video10{
  width: 43%;
} */

  #all {
    height: 500px;
    margin-bottom: 124px;
  }

}