/* .........................Logout drop-down........................................... */
#logout-container {
    position: relative;
  }
  
  .logout-dropdown {
    /* position: absolute;
    top: 100%;
    left: 0;
   
   
    border: 1px solid black;
    box-shadow: 0px 8px 16px 0px black; */
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 100%;
    left: -40%;
    transform: translate(-50%, 15%);
    background-color: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 162px;
    z-index: 1;
  }
  .logout-dropdown::before{
    content: "";
    position: absolute;
    top: -25%;
    left: 80%;
    transform: translate(-90%, -25%);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
  #edit-button {
    width: 160px;
    height: 39px;
    border: none;
    background-color: white;
    font-family: 'Poppins', sans-serif;
}
#edit-button:hover{
    background-color: orange;
    color: black;
}

 #admin-logout{
    width: 160px;
    height: 39px;
    border: none;
    font-family: 'Poppins', sans-serif;
    background-color: white;
 }
 #admin-logout:hover{
    background-color: orange;
    color: black;
 }

/* .........................Logout drop-down........................................... */




body {
    overflow-x: hidden;
 }

 .platform1{
    overflow: hidden;
 }
/* Toggle Styles */

#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled {
    padding-left: 250px;
}

#sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 250px;
    width: 0;
    height: 100%;
    margin-left: -250px;
    overflow-y: auto;
    background: #000;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
    width: 250px;
}

#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
    background-color: orange;
}

#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -250px;
}

/* Sidebar Styles */

.sidebar-nav {
    position: absolute;
    top: 0;
    width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-nav li {
    text-indent: 20px;
    line-height: 40px;
}

.sidebar-nav li span {
    display: block;
    text-decoration: none;
    color: #999999;
}

.sidebar-nav li span:hover {
    text-decoration: none;
    color: #fff;
    background: rgba(255,255,255,0.2);
}

.sidebar-nav li span:active,
.sidebar-nav li span:focus {
    text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
}

.sidebar-nav > .sidebar-brand span {
    color: #999999;
}

.sidebar-nav > .sidebar-brand span:hover {
    color: #fff;
    background: none;
}
/* .......................................................... */
#icon1 {
    font-size: 30px;
    margin-bottom: 6px;
    margin-left: 60px;
}
.part-sec1{
    font-size: 30px;
}
/* .admin-set span{
    display: flex;
    flex-direction: row;
    gap: 20px;
} */

/* ............................................................. */






#menu-toggle{
    font-size: 30px;
}
#image-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
}
#image {
    height: 62px;
    width: 98px;
    /* margin-left: 21px; */
}
.disp{
    display: flex;
    justify-content: space-between;
    
}
#log-out{
    font-size: 12px;
    height: 36px;
    width: 62px;
    margin-top: 14px;
    border-radius: 12px;
    border: none;
}
#log-out:hover{
    background-color: black;
    color: white;
}
.board-sec {
    margin-top: 30px;
    cursor: pointer;
}
.board-sec a{
    text-decoration: none;
    
}
#pg-sec{
    font-size: 23px;
    margin-top: 48px;
}
#menu-sec{
font-size: 23px;
margin-top: 20px;
}






.menu-contents {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 236px;
}
.menu-head{
    width: 80%;
}
.menu-head h1{
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
/* ..................................................................................... */
.add-all{
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-cont{
    width: 79%;
    height: 76px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    
}
.add-cont input {
    width: 217px;
    height: 41px;
}
.add-cont label{
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
}
.add-cont button{
    background-color: black;
    color: #fff;
    border: none;
    height: 42px;
    width: 78px;
    border-radius: 7px;
    font-family: 'Poppins', sans-serif;
}
.add-cont button:hover{
    background-color: orange;
    color: black;
}


/* ...........dropdown............... */

.input-container {
    position: relative;
  }
  
  .dropdown {
    position: absolute;
    top: 69%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px;
    width: 218px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    
  }
  .input-container:hover .dropdown {
    display: block; /* Show the dropdown content on hover */
  }
  
  .dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown ul li {
    padding: 5px 10px;
    cursor: pointer;
  }
  
 
  /* Add a hover effect */
  .dropdown ul li:hover {
    background-color: black; 
    color: #fff;
  }
  /* #menu-content1,#menu-content2,#menu-content3{
    list-style-type: none;
  } */
  /* #menu-content1:hover{
    background-color: orange;
    color: #000;
  } */
  /* CSS */
.dropdown-input {
    padding-right: 25px; /* Add padding to make space for the dropdown icon */
  }
  
  .dropdown-icon {
    position: absolute;
    top: 26%;
    right: 22px;
    transform: translateY(-50%);
    cursor: pointer;
}

/* ...........dropdown............... */
/* ........................................................................................ */


.menu-sec{
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-items{
    width: 79%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex-direction: column;
    height: 100vh;
}


.line1{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}
.menu-dlt1{
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-dlt1 button{
    background-color: orange;
    border: none;
    height: 42px;
    width: 78px;
    border-radius: 7px;
    font-family: 'Poppins', sans-serif;
}
.menu-dlt1 button:hover{
    background-color: #000;
    color: #fff;
}
.line2{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}
.menu-dlt2{
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-dlt2 button{
    background-color: orange;
    border: none;
    height: 42px;
    width: 78px;
    border-radius: 7px;
    font-family: 'Poppins', sans-serif;
}
.menu-dlt2 button:hover{
    background-color: #000;
    color: #fff;
}
.line3{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}
.menu-dlt3{
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-dlt3 button{
    background-color: orange;
    border: none;
    height: 42px;
    width: 78px;
    border-radius: 7px;
    font-family: 'Poppins', sans-serif;
}
.menu-dlt3 button:hover{
    background-color: #000;
    color: #fff;
}
.line4{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}
.menu-dlt4{
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-dlt4 button{
    background-color: orange;
    border: none;
    height: 42px;
    width: 78px;
    border-radius: 7px;
    font-family: 'Poppins', sans-serif;
}
.menu-dlt4 button:hover{
    background-color: #000;
    color: #fff;
}
.line5{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}
.menu-dlt5{
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-dlt5 button{
    background-color: orange;
    border: none;
    height: 42px;
    width: 78px;
    border-radius: 7px;
    font-family: 'Poppins', sans-serif;
}
.menu-dlt5 button:hover{
    background-color: #000;
    color: #fff;
}
.line6{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}
.menu-dlt6{
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-dlt6 button{
    background-color: orange;
    border: none;
    height: 42px;
    width: 78px;
    border-radius: 7px;
    font-family: 'Poppins', sans-serif;
}
.menu-dlt6 button:hover{
    background-color: #000;
    color: #fff;
}
.line7{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}
.menu-dlt7{
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-dlt7 button{
    background-color: orange;
    border: none;
    height: 42px;
    width: 78px;
    border-radius: 7px;
    font-family: 'Poppins', sans-serif;
}
.menu-dlt7 button:hover{
    background-color: #000;
    color: #fff;
}


.cont-1{
    border: 1px solid #dcdcde;
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px rgb(241, 236, 236);

    display: flex;
    justify-content: center;
    align-items: center;
    width: 360px;
    height: 56px;
}
.adjust1{
    width: 62%;
}
.adjust1 h3{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 23px;
}
.cont-2{
    border: 1px solid #dcdcde;
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px rgb(241, 236, 236);

    display: flex;
    justify-content: center;
    align-items: center;
    width: 360px;
    height: 56px;
}
.adjust2{
    width: 62%;
}
.adjust2 h3{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 23px;
}
.cont-3{
    border: 1px solid #dcdcde;
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px rgb(241, 236, 236);

    display: flex;
    justify-content: center;
    align-items: center;
    width: 360px;
    height: 56px;
}
.adjust3{
    width: 62%;
}
.adjust3 h3{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 23px;
}
.cont-4{
    border: 1px solid #dcdcde;
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px rgb(241, 236, 236);

    display: flex;
    justify-content: center;
    align-items: center;
    width: 360px;
    height: 56px;
}
.adjust4{
    width: 62%;
}
.adjust4 h3{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 23px;
}
.cont-5{
    border: 1px solid #dcdcde;
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px rgb(241, 236, 236);

    display: flex;
    justify-content: center;
    align-items: center;
    width: 360px;
    height: 56px;
}
.adjust5{
    width: 62%;
}
.adjust5 h3{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 23px;
}
.cont-6{
    border: 1px solid #dcdcde;
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px rgb(241, 236, 236);

    display: flex;
    justify-content: center;
    align-items: center;
    width: 360px;
    height: 56px;
}
.adjust6{
    width: 62%;
}
.adjust6 h3{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 23px;
}
.cont-7{
    border: 1px solid #dcdcde;
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px rgb(241, 236, 236);

    display: flex;
    justify-content: center;
    align-items: center;
    width: 360px;
    height: 56px;
}
.adjust7{
    width: 62%;
}
.adjust7 h3{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 23px;
}
/* .cont-sec1 {
    width: 30%;
    height: 64px;
} */
/* .cont-1{
    
    border: 0px solid black;
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px rgb(194, 183, 183);
} */


@media only screen and (max-width: 600px) {
    
    .menu-contents{
        height: 356px;
    }
}
@media only screen and (max-width: 579px){
    .menu-items{
        height: 114vh;
        
    }
    .cont-1{
        width: 236px;
        height: 45px;
       } 
       .cont-2{
        width: 236px;
        height: 45px;
       } 
       .cont-3{
        width: 236px;
        height: 45px;
       } 
       .cont-4{
        width: 236px;
        height: 45px;
       } 
       .cont-5{
        width: 236px;
        height: 45px;
       } 
       .cont-6{
        width: 236px;
        height: 45px;
       } 
       .cont-7{
        width: 236px;
        height: 45px;
       } 
       .adjust1 h3{
        font-size: 17px;
        }
        .adjust2 h3{
            font-size: 17px;
        }
        .adjust3 h3{
            font-size: 17px;
        }
        .adjust4 h3{
         font-size: 17px;
        }
        .adjust5 h3{
         font-size: 17px;
        }
        .adjust6 h3{
            font-size: 17px;
        }
        .adjust7 h3{
            font-size: 17px;
        }
        
        .add-cont input {
        width: 115px;
        height: 27px;
    }
        
        
        .add-cont label{
        font-size: 17px
        }
        .add-cont button{
            margin-top: -7px;
        }

        .add-cont input {
            width: 115px;
            height: 30px;
            margin-top: -1px;
        }
}
@media only screen and (max-width: 423px){
    .dropdown-icon {
        position: absolute;
        top: 18%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
    }
    .add-cont {
        width: 89%;
        
    }
    .menu-items {
        width: 89%;
        
    }
    .menu-head {
        width: 92%;
    }
}
@media only screen and (max-width: 377px){
    .dropdown-icon {
       
        top: 15%;
        right: 10px;
    }

.add-cont button{
    height: 37px;
    width: 71px;
    font-size: 15px;
}
.add-cont input {
    width: 115px;
    height: 30px;
    margin-top: -3px;
}
.cont-1 {
    width: 221px;
    height: 45px;
}
.cont-2{
    width: 221px;
    height: 45px;
   } 
   .cont-3{
    width: 221px;
    height: 45px;
   } 
   .cont-4{
    width: 221px;
    height: 45px;
   } 
   .cont-5{
    width: 221px;
    height: 45px;
   } 
   .cont-6{
    width: 221px;
    height: 45px;
   } 
   .cont-7{
    width: 221px;
    height: 45px;
   } 
.adjust1 h3 {
    font-size: 16px;
}
.adjust2 h3 {
    font-size: 15px;
}
.adjust2 h3{
    font-size: 16px;
}
.adjust3 h3{
    font-size: 16px;
}
.adjust4 h3{
    font-size: 16px;
}
.adjust5 h3{
    font-size: 16px;
}
.adjust6 h3{
    font-size: 16px;
}
.adjust7 h3{
    font-size: 16px;
}
.add-cont label {
    font-size: 15px;
}

}

@media only screen and (max-width: 358px){
    .menu-items {
        width: 93%;
    }
    .add-cont {
        width: 92%;
    }
}
@media only screen and (max-width: 343px){
    .add-cont {
        width: 94%;
    }
    .menu-items {
        width: 96%;
    }
    .menu-head {
        width: 95%;
    }
}
@media only screen and (max-width: 335px){
    .add-cont {
        width: 96%;
    }
}
@media only screen and (max-width: 332px){
    .menu-items {
        width: 98%;
    }
}
@media only screen and (max-width: 322px){
    .add-cont {
        width: 97%;
        gap: 15px;
    }
    .menu-items {
        width: 100%;
    }
    .menu-items {
        width: 97%;
    }
    .line1 {
        
        gap: 11px;
    }
    .line2 {
        
        gap: 11px;
    }
    .line3 {
        
        gap: 11px;
    }
    .line4 {
        
        gap: 11px;
    }
    .line5 {
        
        gap: 11px;
    }
    .line6 {
        
        gap: 11px;
    }
    .line7 {
        
        gap: 11px;
    }
    
}

@media only screen and (min-width: 600px){
    .menu-contents{
        height: 334px;
    }
    .add-cont {
        width: 79%;
    }
}
@media(min-width:768px) {
    #wrapper {
        padding-left: 250px;
    }

    #wrapper.toggled {
        padding-left: 0;
    }

    #sidebar-wrapper {
        width: 250px;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 0;
    }

    #page-content-wrapper {
        padding: 20px;
        position: relative;
    }

    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
    }


    .add-cont {
        width: 93%;
    }
    .menu-head {
        width: 94%;
    }
    .menu-items{
        height: 120vh;
        width: 93%;
    }
}

@media only screen and (min-width: 992px){
    .part-sec1{
        font-size: 24px;
    }
    #icon1{
        font-size: 24px;
        margin-bottom: 6px;
        margin-left: 75px;
    }
    #pg-sec{
        font-size: 19px;
    }
    #menu-sec{
        font-size: 19px;
    }
    .menu-head {
        width: 80%;
    }
    .add-cont {
        width: 79%;
    }
    .menu-items {
        height: 120vh;
        width: 79%;
    }
    .add-cont input {
        width: 219px;
        height: 41px;
    }
}