.adminlogin{
    overflow: hidden;
}
.admin-title{
    text-align: center;
    font-family: 'Poppins', sans-serif;
}
.form_input{
    display: flex;
    justify-content: center;
}
.email{
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    margin-left: 30px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}
.email input{
    height: 37px;
    border: none;
    border-radius: 3px;
}
.email label{
    margin-top: 5px;
}
.password{
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    
}
.password input{
    height: 37px;
    border: none;
    border-radius: 3px;
}
.password label{
    margin-top: 5px;
}
.datas {
    height: 349px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
    box-shadow: 0 2px 25px rgba(0, 0, 0, 0.2);
    width: 568px;
    margin-top: 20px;
    background-color: orange;
    border-radius: 5px;
}
.form_container_card{
    height: 536px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}
.click-admin{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}
.click-admin button {
    border: none;
    border-radius: 4px;
    background-color: black;
    color: white;
    height: 42px;
    width: 73px;
}
/* .click-admin button:hover{
    background-color: white;
    color: black;
} */
.log-in{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

@media only screen and (max-width: 600px){
    .form_container_card {
        height: 536px;
       
        margin-top: 72px;
    }
    .datas {
        height: 349px;
        width: 492px;
    }
    .email {
        gap: 10px;
        margin-left: 17px;
        margin-bottom: 20px;
    }
    .password {
        gap: 10px;
        margin-bottom: 20px;
        margin-right: 13px;
    }
}
@media only screen and (max-width: 521px){
    .datas {
        height: 349px;
        width: 460px;
    }
    .email input {
        height: 37px;
        width: 262px;
        margin-right: 20px;
    }
    .password input {
        height: 37px;
        width: 263px;
        margin-right: 21px;
    }
    .click-admin {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 18px;
        font-family: 'Poppins', sans-serif;
        margin-left: 23px;
    }
    
}
@media only screen and (max-width: 490px){
    .datas {
        height: 349px;
        width: 403px;
    }
    .email {
        gap: 10px;
        margin-left: 40px;
        margin-bottom: 20px;
    }
    .password {
        gap: 10px;
        margin-bottom: 20px;
        margin-right: -7px;
    }
    .click-admin {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 18px;
        font-family: 'Poppins', sans-serif;
        margin-left: 43px;
    }
}
@media only screen and (max-width: 425px){
    .datas {
        height: 349px;
        width: 336px;
    }
    .email {
        gap: 10px;
        margin-left: 39px;
        margin-bottom: 20px;
        flex-direction: column;
    }
    .email input {
        height: 37px;
        width: 242px;
        margin-right: 41px;
    }
    .password {
        gap: 10px;
        margin-bottom: 20px;
        margin-left: 40px;
        flex-direction: column;
    }
    .password input {
        height: 37px;
        width: 241px;
        margin-right: 39px;
    }
    .click-admin button {
        height: 42px;
        width: 57px;
    }
    .click-admin {
        gap: 18px;
        margin-left: -21px;
    }
}
@media only screen and (max-width: 375px){
    .form_container_card {
    height: 536px;
    margin-top: 44px;
}
}
@media only screen and (max-width: 360px){
    .datas {
    height: 349px;
    width: 301px;
}
.form_container_card {
    height: 536px;
    margin-top: 37px;
}
}
@media only screen and (max-width: 330px){
    .email input {
        height: 37px;
        width: 211px;
        margin-right: 41px;
    }
    .email {
        gap: 10px;
        margin-left: 50px;
        margin-bottom: 20px;
        flex-direction: column;
    }
    .password {
        gap: 10px;
        margin-bottom: 20px;
        margin-left: 51px;
        flex-direction: column;
    }
    .password input {
        height: 37px;
        width: 209px;
        margin-right: 39px;
    }
    .password {
        gap: 10px;
        margin-bottom: 20px;
        margin-left: 35px;
        flex-direction: column;
    }
    .email {
        gap: 10px;
        margin-left: 33px;
        margin-bottom: 20px;
        flex-direction: column;
    }
    .click-admin {
        gap: 18px;
        margin-left: 5px;
    }
    .datas {
        height: 349px;
        width: 283px;
    }
}

@media only screen and (max-width: 320px) {
    .form_container_card {
        height: 536px;
        margin-top: -36px;
    }
}

@media only screen and (min-width: 600px){
    .datas {
        height: 349px;
       
        width: 557px;
        margin-top: 20px;
        
    }
    .form_container_card {
        height: 536px;
        margin-top: 128px;
    }
}
@media only screen and (min-width: 800px){
    .form_container_card {
        height: 536px;
        margin-top: 249px;
    }
}
@media only screen and (min-width: 820px){
    .form_container_card {
        height: 536px;
        margin-top: 185px;
    }
}
@media only screen and (min-width: 1200px){
    .form_container_card {
        height: 536px;
        margin-top: 95px;
    }
}