/* .swiper {
    width: 100%;
    height: 100%;
  } */
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .swiper {
    margin-left: auto;
    margin-right: auto;
  }
  #test{
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin-bottom: 29px;
  }
  @media screen and (max-width: 1198px){
    .swiper{
      z-index: 0;
    }
    #all-sets{
      margin-bottom: 126px;
    }
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
      left: var(--swiper-navigation-sides-offset, 123px);
      right: auto;
  }
  .swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, 123px);
    left: auto;
}
.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
  display: none;
}
  }
  /* @media screen and (max-width: 768px){
    #small-view{
      margin-bottom: 117px;
    }
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
      left: var(--swiper-navigation-sides-offset, 60px);
      right: auto;
  }
  .swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, 60px);
    left: auto;
}

  } */
  @media only screen and (max-width: 885px){
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
      left: var(--swiper-navigation-sides-offset, 148px);
      
  }
  .swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, 148px);
    
}
#set-1{
  width: 450px;
}
#set-2{
  width: 450px;
}
#set-3{
  width: 450px;
}
#set-4{
  width: 450px;
}
#set-5{
  width: 450px;
}
#set-6{
  width: 450px;
}
#set-7{
  width: 450px;
}
#set-8{
  width: 450px;
}
#set-9{
  width: 450px;
}
  }

  @media only screen and (max-width: 820px){
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
      left: var(--swiper-navigation-sides-offset, 76px);
  }
  .swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, 76px);
    
}
  }
  @media only screen and (max-width: 665px){
    #set-1{
      width: 300px;
    }
    #set-2{
      width: 300px;
    }
    #set-3{
      width: 300px;
    }
    #set-4{
      width: 300px;
    }
    #set-5{
      width: 300px;
    }
    #set-6{
      width: 300px;
    }
    #set-7{
      width: 300px;
    }
    #set-8{
      width: 300px;
    }
    #set-9{
      width: 300px;
    }
  }
  @media only screen and (max-width: 510px){
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
      left: var(--swiper-navigation-sides-offset, 35px);
  }
  .swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, 35px);
    
}
  }

  @media only screen and (max-width: 428px){
    #set-1{
      width: 250px;
    }
    #set-2{
      width: 250px;
    }
    #set-3{
      width: 250px;
    }
    #set-4{
      width: 250px;
    }
    #set-5{
      width: 250px;
    }
    #set-6{
      width: 250px;
    }
    #set-7{
      width: 250px;
    }
    #set-8{
      width: 250px;
    }
    #set-9{
      width: 250px;
    }
  }
  @media only screen and (max-width: 372px){
    #set-1{
      width: 250px;
      height: 280px;
    }
    #all-sets {
      margin-bottom: 81px;
  }
  .swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, 22px);
    top: 139px;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 22px);
  top: 139px;
}
  }

  @media only screen and (max-width: 346px){
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
      left: var(--swiper-navigation-sides-offset, 6px);
      top: 139px;
  }
  .swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, 6px);
    top: 139px;
  }
  }