/* ---------------------RichTeaxtEditor--------------------------------------- */
.jodit-status-bar{
    visibility: hidden;
}
.jodit-react-container{
    width: 500px;
}
#details{
    font-family: 'Poppins', sans-serif;
}
/* ---------------------RichTeaxtEditor--------------------------------------- */

/* .........................Logout drop-down........................................... */
#logout-container {
    position: relative;
  }
  
  .logout-dropdown {
    /* position: absolute;
    top: 100%;
    left: 0;
   
   
    border: 1px solid black;
    box-shadow: 0px 8px 16px 0px black; */
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 100%;
    left: -40%;
    transform: translate(-50%, 15%);
    background-color: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 162px;
    z-index: 1;
  }
  .logout-dropdown::before{
    content: "";
    position: absolute;
    top: -25%;
    left: 80%;
    transform: translate(-90%, -25%);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
  #edit-button {
    width: 160px;
    height: 39px;
    border: none;
    background-color: white;
    font-family: 'Poppins', sans-serif;


}
#edit-button:hover{
    background-color: orange;
    color: black;
}

 #admin-logout{
    width: 160px;
    height: 39px;
    border: none;
    font-family: 'Poppins', sans-serif;
    background-color: white;
 }
 #admin-logout:hover{
    background-color: orange;
    color: black;
 }

/* .........................Logout drop-down........................................... */






body {
    overflow-x: hidden;
 }

 .platform1{
    overflow: hidden;
 }
/* Toggle Styles */

#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled {
    padding-left: 250px;
}

#sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 250px;
    width: 0;
    height: 100%;
    margin-left: -250px;
    overflow-y: auto;
    background: #000;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
    width: 250px;
}

#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
    background-color: orange;
}

#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -250px;
}

/* Sidebar Styles */

.sidebar-nav {
    position: absolute;
    top: 0;
    width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-nav li {
    text-indent: 20px;
    line-height: 40px;
}

.sidebar-nav li span {
    display: block;
    text-decoration: none;
    color: #999999;
}

.sidebar-nav li span:hover {
    text-decoration: none;
    color: #fff;
    background: rgba(255,255,255,0.2);
}

.sidebar-nav li span:active,
.sidebar-nav li span:focus {
    text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
}

.sidebar-nav > .sidebar-brand span {
    color: #999999;
}

.sidebar-nav > .sidebar-brand span:hover {
    color: #fff;
    background: none;
}
#pg-sec{
    font-size: 23px;
    /* ................................................ */
    
        margin-top: 48px;
    
    /* ................................................... */
}
/* .......................................................... */
#icon1 {
    font-size: 30px;
    margin-bottom: 6px;
    margin-left: 60px;
}
.part-sec1{
    font-size: 30px;
}
/* .admin-set span{
    display: flex;
    flex-direction: row;
    gap: 20px;
} */

/* ............................................................. */






#menu-toggle{
    font-size: 30px;
}
#image-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
}
#image {
    height: 62px;
    width: 98px;
    /* margin-left: 21px; */
}
.disp{
    display: flex;
    justify-content: space-between;
    
}
#log-out{
    font-size: 12px;
    height: 36px;
    width: 62px;
    margin-top: 14px;
    border-radius: 12px;
    border: none;
}
#log-out:hover{
    background-color: black;
    color: white;
}
.board-sec {
    margin-top: 30px;
    cursor: pointer;
}
.board-sec a{
    text-decoration: none;
    
}













/* .banner-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
} */

/* .input-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 80px;
} */

.input-container label {
    font-size: 20px;
}

#banner-input {
    height: 40px;
    border: 1px solid #000000;
    border-radius: 5px;
    margin-top: 10px;
}

#banner-input:focus {
    outline: #000000;
}

.textarea-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 150px;
    margin: 10px;
}

.textarea-container label {
    font-size: 20px;
}

.textarea-container textarea {
    height: 70%;
    border-radius: 5px;
    margin-top: 10px;
}

.textarea-container textarea:focus {
    outline: none;
}

.btn-container {
    display: flex;
    justify-content: center;
}

.btn-container button {
    width: 68%;
    padding: 6px;
    background-color: #D50000;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin: 25px;
}

.banner-img {
    display: flex;
    justify-content: center;
    padding: 30px 0 30px 0;
}

.profile-pic {
    color: transparent;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
}

.avatar-upload {
    position: relative;
    /* max-width: 205px; */
    margin: 50px auto;
}

.avatar-upload .avatar-edit {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 10px;
}

.avatar-upload .avatar-edit input {
    display: none;
}

.avatar-upload .avatar-edit input+label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input+label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input+label:after {
    content: "\f040";
    font-family: 'FontAwesome';
    color: #757575;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
}

.avatar-upload .avatar-preview {
    width: 500px;
    height: 250px;
    position: relative;
    /* border-radius: 100%; */
    /* border: 6px solid #F8F8F8; */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-preview>div {
    width: 100%;
    height: 100%;
    /* border-radius: 100%; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.table-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* height: 100vh; */
}
/* .....................................upload-image---------------------------------- */

.file-input{
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}
.file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  
  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 10px 35px;
    background-color: #4245a8;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    width: 100%;
  }
  
  .file-input__label svg {
    height: 16px;
    margin-right: 4px;
  }
/* .....................................upload-image---------------------------------- */
  .table-location{
    border: 1px solid black;
    height: 708px;
    width: 74%;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
.header-imgs{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 178px;
    flex-direction: column;
}
.header-imgs input {
    width: 101%;
    height: 27%;
}
.teaxt-areas{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.final{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 157px;
}
.final button {
    height: 30%;
    width: 99%;
    background-color: #D50000;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
}
.banner-label label{
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
}
.header-imgs label{
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
}
.teaxt-areas label{
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
}
.teaxt-areas textarea{
    resize: none;
}


@media only screen and (max-width: 600px){
    
    .banner-header {
        width: 100%;
        height: 45rem;
    }
    .teaxt-areas textarea {
        width: 68%;
        height: 79px;
    }
    .final button {
        height: 30%;
        width: 68%;
    }
    .table-location {
        /* height: 111vh; */
        margin-top: 140px;
        height: 734px;
        width: 89%;
    }
    .jodit-react-container {
        width: 431px;
    }
    .header-imgs {
        
        height: 118px;
        
    }
    .final {
        
        height: 80px;
        margin-top: 20px;
    }
    .final button {
        height: 67%;
        width: 68%;
    }
    .header-imgs input {
        width: 89%;
        height: 32%;
    }

}

@media only screen and (max-width: 500px){
    .jodit-react-container {
        width: 334px;
    }
    .table-location {
        /* height: 111vh; */
        margin-top: 103px;
        height: 628px;
        width: 89%;
    }
}
@media only screen and (max-width: 425px){
    .table-location {
        /* height: 111vh; */
        margin-top: 103px;
        height: 602px;
        width: 89%;
    }
}
@media only screen and (max-width: 400px){
    .jodit-react-container {
        width: 294px;
    }
    .table-location {
        /* height: 111vh; */
        margin-top: 103px;
        height: 552px;
        width: 89%;
    }
    .final {
        height: 60px;
        margin-top: 1px;
    }
    .header-imgs {
        height: 99px;
    }
}
@media only screen and (max-width: 375px){
    .table-location {
        /* height: 111vh; */
        margin-top: 98px;
        height: 532px;
        width: 89%;
    }
    .header-imgs {
        height: 90px;
    }
}
@media only screen and (max-width: 360px){
    .jodit-react-container {
        width: 258px;
        height: 176px;
    }
}
@media only screen and (max-width: 325px){

}

@media only screen and (min-width: 600px){
    .jodit-react-container {
        width: 369px;
    }
    .header-imgs input {
        width: 89%;
        height: 27%;
    }
    .teaxt-areas textarea {
        
        width: 73%;
    }
    .final button {
        height: 30%;
        width: 72%;
    }
    .table-location {
        margin-top: 136px;
        height: 765px;
    }
    .file-input {
        height: 85px;
        
        margin-top: 37px;
    }


}
@media(min-width:768px) {
    #wrapper {
        padding-left: 250px;
    }

    #wrapper.toggled {
        padding-left: 0;
    }

    #sidebar-wrapper {
        width: 250px;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 0;
    }

    #page-content-wrapper {
        padding: 20px;
        position: relative;
    }

    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
    }





    .table-location {
        height: 695px;
        width: 83%;
        margin-top: 41px;
    }
    .header-imgs input {
        width: 89%;
        height: 27%;
    }
}

@media only screen and (min-width: 992px){
    .table-location {
        height: 715px;
        width: 83%;
        margin-top: 47px;
    }
}
@media only screen and (min-width: 1200px){
    .part-sec1{
        font-size: 24px;
    }
    #icon1{
        font-size: 24px;
        margin-bottom: 6px;
        margin-left: 75px;
    }
    #pg-sec{
        font-size: 19px;
    }
    #menu-sec{
        font-size: 19px;
    }
    
    .header-imgs input {
        width: 93%;
        height: 42%;
    }
    .teaxt-areas textarea {
        width: 104%;
        height: 212px;
    }
    .table-location {
        height: 606px;
        width: 74%;
        margin-top: 27px;
    }


    .header-imgs {
        
        height: 117px;
       
    }
    .final {
        height: 160px;
        margin-top: -44px;
    }
    .jodit-react-container {
        width: 617px;
    }
}
@media only screen and (min-width: 1400px){
    
    .table-location {
        height: 606px;
        width: 74%;
        margin-top: 129px;
    }
    .header-imgs input {
        width: 93%;
        height: 41%;
    }
    .teaxt-areas textarea {
        width: 117%;
        height: 212px;
    }
}
         
