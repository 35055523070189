/* Navbar.css */

.new-navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 115px;
  /* padding: 10px 20px; */
  background-color: #333;
  color: #fff;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1;
  gap: 45rem;
  /* box-shadow: ; */
}

.navbar-logo img {
  height: 80px;
}

.navbar-options {
  list-style: none;
  display: flex;
  gap: 2rem;
  margin-bottom: 0rem;
}

.navbar-options li {
  cursor: pointer;
}

.navbar-options a {
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.navbar-options a div:hover{
  color: rgb(255, 47, 47);
}

/* .dropdown-content a li:nth-child(2){
  border-top: 1px solid rgb(198, 198, 198);
  border-bottom: 1px solid rgb(198, 198, 198);
} */

.search-menu {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}

.search-icon {
  font-size: 16px;
}

.navbar-search-icon{
  cursor: pointer;
}

.search-box {
  display: none;
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 6rem;
  transition: top 0.9s ease-in-out;
}

.search-box.visible {
  top: 0; 
  display: block;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
}

.search-container{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

.search-box input{
  width: 60%;
  height: 3rem;
  font-size: 25px;
  border: none;
  border-bottom: 1px solid grey;
}

.search-box input:focus{
  outline: none;
}

.search-close-button{
  border: none;
  color: black;
  font-size: 40px;
}

.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 2px 0;
}

.sidebar {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  background-color: #fff;
  transition: right 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 1;
  overflow-y: scroll;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
}

.sidebar.activeNav {
  right: 0;
}

.sidebar.inactive {
  /* Hide the sidebar by moving it out of the screen and fading out */
  right: -300px;
  opacity: 0;
}

.close-section {
  display: flex;
  justify-content: center;
  height: 5rem;
}

.close-btn {
  display: flex;
  justify-content: flex-start;
  width: 89%;
}

.close-btn button {
  border: none;
  font-size: 30px;
  background: none;
}

.options-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 20px;
  color: black;
}

.options-section a {
  text-decoration: none;
  color: black;
}

.works-accordion {
  cursor: pointer;
  border-bottom: 1px solid rgb(217, 217, 217);
  padding: 1rem 0;
}

.works-accordion.active .sub-options {
  max-height: 500px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.sub-options {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  display: flex;
  justify-content: flex-end;
}

.sub-options-container {
  text-align: left;
  line-height: 3rem;
}

.sub-options-container a {
  display: block;
  /* padding: 15px 0; /
  color: black;
  text-decoration: none;
  margin-top: 1rem;
  / border-bottom: 1px solid rgb(217, 217, 217); */
}

.sub-options-container a:nth-child(2),
.sub-options-container a:nth-child(1){
  border-bottom: 1px solid rgb(217, 217, 217);
}

.sidebar-option-works {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}

.sidebar-option-works span {
  color: grey;
}

.sidebar-option-home {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 5rem;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  height: 3rem;
  border-bottom: 1px solid rgb(217, 217, 217);
}

.sidebar-option-home span {
  opacity: 0;
}

.sidebar-option-level {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 0rem;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  height: 3rem;
  border-bottom: 1px solid rgb(217, 217, 217);
}

.sidebar-option-level span {
  opacity: 0;
}

.sidebar-option-about {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 4rem;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  height: 3rem;
  border-bottom: 1px solid rgb(217, 217, 217);
}

.sidebar-option-about span {
  opacity: 0;
}

.sidebar-option-features {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 3rem;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  height: 3rem;
  border-bottom: 1px solid rgb(217, 217, 217);
}

.sidebar-option-features span {
  opacity: 0;
}

.sidebar-option-testimonial {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  height: 3rem;
  border-bottom: 1px solid rgb(217, 217, 217);
}

.sidebar-option-testimonial span {
  opacity: 0;
}

.sidebar-option-contact {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 4rem;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  height: 3rem;
  border-bottom: 1px solid rgb(217, 217, 217);
}

.sidebar-option-contact span {
  opacity: 0;
}

/* .sidebar-option-search{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 4rem;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}

.sidebar-option-search span {
  opacity: 0;
} */



@media (min-width: 1024px){
  .new-navbar{
    gap: 11rem;
  }
}

@media (min-width: 1440px){
  .new-navbar{
    gap: 30rem;
  }
}

@media (min-width: 1700px){
  .new-navbar{
    gap: 45rem;
  }
}

@media screen and (min-width: 900px) {
  .menu-icon {
    display: none;
  }

  .navbar-options {
    display: flex;
  }

  /* .sidebar {
    display: none;
  } */

  .search-icon {
    display: none;
  }
}

@media (max-width:1024px){
  .search-box input{
    width: 80%;
  }
  .new-navbar{
    gap: 0;
  }
}

@media (max-width:900px) {
  .new-navbar {
    justify-content: space-between;
    padding: 0 30px;
    gap: 0;
  }

  .navbar-options {
    /* gap: 2rem; */
    display: none;
  }

  .menu-icon {
    display: flex;
  }

  .works-dropdown-content {
    min-width: 272px;
  }
}

@media screen and (max-width: 768px) {
  .new-navbar {
    justify-content: space-between;
    padding: 0 20px;
    gap: 0;
  }

  .navbar-options {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    background-color: #333;
    text-align: center;
    z-index: 1;
  }

  .navbar-options.activeNav {
    display: flex;
  }

  

  .sub-options {
    text-align: left;
  }
}

@media (max-width:600px){
  .new-navbar{
    display: flex;
    justify-content: space-between;
    gap: 0;
  }
  .search-container{
    gap: 1rem;
  }

  .search-box{
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .search-box input{
    width: 90%;
  }
}