@import url('https://fonts.googleapis.com/css?family=Open+Sans');

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700&display=swap');

.failure-email{
    overflow: hidden;
}

.verified-icon{
    color: red;
    font-size: 113px;
}
.verified h1{
    font-size: 32px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}
.verified p{
    font-family: 'Source Sans Pro', sans-serif;
}
.verified button {
    border: none;
    background-color: black;
    color: white;
    height: 60px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    width: 10rem;
    border-radius: 15px;
}

@media only screen and (min-width: 600px){
    .verified{
        margin-top: 13rem;
    }
}
@media only screen and (min-width: 1400px){
    .verified{
        margin-top: 14rem;
    }
}