.total-sec{
    overflow: hidden;
}


.boxes{
    display: flex;
    justify-content: center;
    align-items: center;
   
    flex-direction: column;
}


.banner_sec{
    background: url('../assets/Images/pay-now.jpg');
    background-size: cover;
    height: 324px;
    background-color: rgba(0,0,0,.8);
    background-position: center center;
    
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.sub-banner{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21rem;
    flex-direction: column;
    
}
.over-banner{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.6);
    z-index: 1;
    height: 325px;
}

.sample{
    font-size: 65px;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.sub-banner h3{
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
/* .buttn {
    height: 50px;
    width: 124px;
    border-radius: 20px;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
   
    background-image: radial-gradient(at center center, rgb(253,163,43), rgb(253,163,43) 100%);
    background-size: 0 100%;
    background-repeat: no-repeat;

    background-position: 100% 0;
    transition: background-size 0.8s, background-position 0.8s;


}
.buttn:hover{
   
        background-size: 100% 100%;
        color: white;
  
} */


/* .form-head{
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aqua;
}
.min-forms{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
}
.min-forms button {
    background-color: orange;
    border: none;
    height: 42px;
    width: 94px;
    border-radius: 7px;
    font-family: 'Poppins', sans-serif;
}
.min-forms button:hover{
    background-color: white;
}
.min-forms label{
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
}
.min-forms input{
    height: 50px;
    border: none;
    border-radius: 20px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
} */



.all-sets{
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
}
.main-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 0px solid black;
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px rgb(194, 183, 183);
    height: 467px;
    width: 780px;
    background-color: rgb(237, 219, 219);
 
}
.search-base{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    height: 100px;
}
.search-base input{
    height: 40px;
    border: none;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}
.search-base h6{
    margin-top: 7px;
    font-family: 'Poppins', sans-serif;
}
.search-base button{
    background-color: orange;
    border: none;
    height: 42px;
    width: 50px;
    border-radius: 7px;
}
.check-content{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    /* align-items: center; */
    max-height: 150px;
    overflow-y: scroll;
    
}
.check-content input{
    height: 40px;
    border: none;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
   margin-right: 8px;
}
.check-content button{
    background-color: orange;
    border: none;
    height: 42px;
    width: 50px;
    border-radius: 7px;
}
#minus_btn{
    margin-top: 10px;
}


.align-content{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    height: 50px;
}
.align-content input{
    height: 40px;
    border: none;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}
.align-content button{
    background-color: orange;
    border: none;
    height: 42px;
    width: 50px;
    border-radius: 7px;
}
/* .imgs{
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
} */


/* .file-input{
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}
.file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  
  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 10px 12px;
    background-color: #4245a8;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  }
  
  .file-input__label svg {
    height: 16px;
    margin-right: 4px;
  } */



.submit button {
    background-color: orange;
    border: none;
    height: 42px;
    width: 117px;
    border-radius: 7px;
    font-family: 'Poppins', sans-serif;
}
.submit button:hover{
    background-color: white;
}























/* .page_subsec img {
    height: 375px;
    width: 613px;
}
.page_sub{
 color: white;
}
.page_sub h1{
   font-size: 65px;
   font-family: 'Poppins', sans-serif;
    font-weight: 600;
} 
.page_sub p{
    font-family: 'Poppins', sans-serif;
}
.segment{
    background-color: rgb(132,133,129);
    height: 700px;
}


.namesec {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 206px;
}
.namesec h1{
    font-size: 43px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.namesec p{
    font-family: 'Poppins', sans-serif;
  
}



.order-sec1 img{
    height: 200px;
}
.order-sec2 img{
    height: 200px;
}
.order-sec3 img{
    height: 200px;
}
#feature{
    font-size: 43px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.head_para p{
    font-family: 'Poppins', sans-serif;
}
.box-sec{
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.course1{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.course2{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.course3{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.lorem1{
    font-family: 'Poppins', sans-serif;
}
.lorem2{
    font-family: 'Poppins', sans-serif;
}

.lorem3{
    font-family: 'Poppins', sans-serif;
}





@media only screen and (max-width: 600px) {
    .box-sec {
        height: 2228px;
    }
    
    
    .page_subsec img {
        height: 361px;
        width: 510px;
    }
    .segment {
       
        height: 845px;
    }
    .contains1{
        height: 675px;
    }
    .contains2{
        height: 675px;
    }
    
}

@media only screen and (max-width: 550px){
    
    .page_subsec img {
        height: 361px;
        width: 502px;
    }
    .page_sub h1 {
        font-size: 50px;
    }
    .segment {
        height: 799px;
    }
    .namesec p{
     padding-left: 5px;
    }
   
}

@media only screen and (max-width: 500px){
    .page_subsec img {
        height: 361px;
        width: 452px;
    }
    .box-sec {
        height: 2109px;
    }
    .contains1 {
        height: 626px;
    }
    .contains2 {
        height: 619px;
    }
}

@media only screen and (max-width: 450px){
    .page_subsec img {
        height: 257px;
        width: 402px;
    }
    .namesec p {
        padding-left: 10px;
    }
}
@media only screen and (max-width: 428px){
    .page_subsec img {
        height: 257px;
        width: 380px;
    }
    .namesec p {
        padding-left: 13px;
    }
}
@media only screen and (max-width: 400px){
    .sample {
        font-size: 50px;
        color: white;
        
    }
    .namesec h1 {
        font-size: 32px;
    }
    #feature {
        font-size: 32px;
    }
    .page_sub h1 {
        font-size: 34px;
    }
    .page_subsec img {
        height: 257px;
        width: 352px;
    }
    .box-sec {
        height: 1899px;
    }
    .contains1 {
        height: 565px;
    }
    .contains2 {
        height: 564px;
    }
    .namesec p {
        padding-left: 18px;
    }

}

@media only screen and (max-width: 390px){
    .page_subsec img {
        height: 257px;
        width: 342px;
    }
}
@media only screen and (max-width: 360px){
    .page_subsec img {
        height: 257px;
        width: 312px;
    }
    .namesec p {
        padding-left: 9px;
    }
}
@media only screen and (max-width: 350px){
    .page_subsec img {
        height: 219px;
        width: 303px;
    }
    .namesec p {
        padding-left: 4px;
    }
}
@media only screen and (max-width: 330px){
    .page_subsec img {
        height: 219px;
        width: 282px;
    }
}
@media only screen and (max-width: 320px){
    .page_subsec img {
        height: 186px;
        width: 272px;
    }
}


@media only screen and (min-width: 600px){
    .box-sec {
        height: 2228px;
    }
    
    
    .page_subsec img {
        height: 361px;
        width: 510px;
    }
    .segment {
       
        height: 845px;
    }
    .contains1{
        height: 675px;
    }
    .contains2{
        height: 675px;
    }
}

@media only screen and (min-width: 768px){
    .page_subsec img {
        height: 388px;
        width: 696px;
    }
    .box-sec {
        height: 809px;
    }
    .head_para{
        margin-top: 99px;
    }
    .value1{
        height: 548px;
    }
    
}

@media only screen and (min-width: 992px){
    .page_sub h1 {
        font-size: 51px;
    }
    .segment {
        height: 550px;
    }
    .page_subsec img {
        height: 365px;
        width: 457px;
    }
    .value1 {
        height: 556px;
    }
}

@media only screen and (min-width: 1200px){
    .segment {
        height: 700px;
    }
    .page_subsec img {
        height: 365px;
        width: 550px;
    }
    .value2 {
        height: 556px;
    }
    .value3 {
        height: 556px;
    }
    .page_subsec img {
        height: 396px;
        width: 550px;
    }
    
}

@media only screen and (min-width: 1400px){
    .page_subsec img {
        height: 365px;
        width: 633px;
    }
    .value1 {
        height: 565px;
    }
    .value2 {
        height: 565px;
    }
    .value3 {
        height: 565px;
    }
    .page_subsec img {
        height: 439px;
        width: 633px;
    }
} */
